import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Container,
  Heading,
  HStack,
  Img,
  LinkBox,
  LinkOverlay,
  Stack,
  Text,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react";
import { gql } from "graphql-request";
import { usePlpData } from "../context";
import { TagText } from "@/modules/college/plp/components/all-colleges/CollegeCard";

export const Banner = () => {
  const {
    plp: { banner_title, banner_button_text, banner_button_link, banner_text, banner_image },
  } = usePlpData();

  const backgroundGradient = useColorModeValue(
    "linear-gradient(to right, #276749, #0076A6)",
    "linear-gradient(to right, #9AE6B4, #B2E4F1)",
  );

  return (
    <Container
      maxWidth="container.xl"
      px={[0, null, 4]}
      position="relative"
      transition="transform 0.3s"
      willChange="transform"
      _hover={{ transform: "translateY(-2px)" }}
    >
      <TagText content="جدید" right={10} />
      <Stack
        as={LinkBox}
        direction={["column", null, null, "row"]}
        align="center"
        justify="space-between"
        bg={backgroundGradient}
        w="full"
        minH={60}
        rounded={["none", null, "xl"]}
        p={8}
        pt={[16, null, null, 8]}
        spacing={6}
        color="text.normal.reverse"
      >
        <VStack align={["center", null, null, "start"]} justify="space-between" spacing={4}>
          {banner_title && (
            <Heading size={["lg", null, null, "xl"]} fontWeight="bold" textAlign="center">
              {banner_title}
            </Heading>
          )}
          {banner_text && <Text textAlign="center">{banner_text}</Text>}
          <HStack align="center" spacing={1} fontWeight="semibold" display={["none", null, null, "flex"]}>
            <LinkOverlay href={banner_button_link}>{banner_button_text}</LinkOverlay>
            <ChevronLeftIcon boxSize={6} />
          </HStack>
        </VStack>
        {banner_image && <Img src={banner_image} alt="دوره جدید" borderRadius="xl" w="xs" h="auto" />}
        <HStack align="center" spacing={1} fontWeight="semibold" display={["flex", null, null, "none"]}>
          <LinkOverlay href={banner_button_link}>{banner_button_text}</LinkOverlay>
          <ChevronLeftIcon boxSize={6} />
        </HStack>
      </Stack>
    </Container>
  );
};

Banner.fragment = {
  collegePLP: gql`
    fragment BannerFragment on CollegePLPType {
      banner_is_active
      banner_title
      banner_text
      banner_button_text
      banner_button_link
      banner_image
    }
  `,
};
